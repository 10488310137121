import React, { createContext, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, type = 'success') => {
        setNotifications((prev) => [...prev, { message, type }]);
        setTimeout(() => {
            setNotifications((prev) => prev.filter((_, index) => index !== 0));
        }, 3000); // Удаляем уведомление через 3 секунды
    };

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            {children}
            <NotificationList notifications={notifications} />
        </NotificationContext.Provider>
    );
};

const NotificationList = ({ notifications }) => {
    return (
        <div className="fixed bottom-10 right-10 p-4 z-50 space-y-2">
            {notifications.map((notification, index) => (
                <NotificationItem key={index} notification={notification} />
            ))}
        </div>
    );
};

const NotificationItem = ({ notification }) => {
    const icon = {
        success: <FontAwesomeIcon icon={faCheckCircle} className="h-6 w-6 text-white" />,
        error: <FontAwesomeIcon icon={faExclamationCircle} className="h-6 w-6 text-white" />,
        warning: <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6 text-white" />,
    }[notification.type];

    const bgColor = {
        success: 'bg-green-500',
        error: 'bg-red-500',
        warning: 'bg-yellow-500',
    }[notification.type];

    return (
        <motion.div
            initial={{ opacity: 0, translateY: 20 }} // Начальная анимация
            animate={{ opacity: 1, translateY: 0 }} // Анимация появления
            exit={{ opacity: 0, translateY: 20 }} // Анимация исчезновения
            transition={{ duration: 0.3 }} // Длительность анимации
            className={`${bgColor} flex items-center text-white p-4 rounded shadow-md`}
        >
            <div className="mr-3">{icon}</div>
            <div className="flex-1">{notification.message}</div>
        </motion.div>
    );
};
