import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';

const CreateTaskPage = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone: '',
    type: '',
    name: '',
    model: '',
    address: '',
    scheduledPickupDate: '',
    description: '',
    order: 0,
    otv: store.user.id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/zayssitecur/newzayssitecur`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Обработка успешного создания задания
        console.log('Задание создано');
        navigate(-1); // Перенаправление на страницу курьера
      } else {
        console.error('Ошибка при создании задания', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h2 className="text-2xl font-bold mb-6">Создать задание</h2>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-96">
        <div className="mb-4">
          <label className="block mb-1">Телефон:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} required className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Тип:</label>
          <input type="text" name="type" value={formData.type} onChange={handleChange} className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Имя:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Модель:</label>
          <input type="text" name="model" value={formData.model} onChange={handleChange} className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Адрес:</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} required className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Запланированная дата забора:</label>
          <input type="datetime-local" name="scheduledPickupDate" value={formData.scheduledPickupDate} onChange={handleChange} required className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Комментарии:</label>
          <textarea name="description" value={formData.description} onChange={handleChange} className="border rounded px-2 py-1 w-full" />
        </div>
        <div className="flex justify-end">
          <button type="button" onClick={() => navigate(-1)} className="bg-gray-300 px-4 py-2 rounded mr-2">
            Отмена </button>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Создать </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTaskPage;
