import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, LayoutGroup } from 'framer-motion';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';

const OrganizationDetails = ({ org }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('employees');
    const [searchQuery, setSearchQuery] = useState('');
    const [sotrudniki, setSotrudniki] = useState([]); // Для хранения списка сотрудников
    const [filials, setFilials] = useState([]); // Для хранения списка сотрудников
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showAddEmployee, setShowAddEmployee] = useState(false); // Для управления видимостью формы добавления
    const [userID, setUserID] = useState([]); // Список пользователей для добавления сотрудника
    const [selectedUser, setSelectedUser] = useState(null); // Выбранный пользователь
    const [position, setPosition] = useState(''); // Должность сотрудника
    const [employeeRole, setEmployeeRole] = useState('');
    const [selectedFilialID, setSelectedFilialID] = useState('');
    const { store } = useContext(Context);
    const [organization, setOrganization] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddFilial, setShowAddFilial] = useState(false);

    const [showEmployees, setShowEmployees] = useState({});

    const roles = ['orgAdmin', 'orgSotr'];

    useEffect(() => {
        console.log(store);
        const fetchZapSSiteById = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/organizations/organizationsById/${id}`);
                setOrganization(response.data);
                axios.get(`${API_URL}/organizations/filials`, { params: { id } }) // Выполните GET-запрос к API
                    .then(response => {
                        setFilials(response.data); // Сохраните полученные данные в состоянии
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchZapSSiteById();

    }, [id]); // Запустите этот эффект при изменении activeTab или id

    // Функция для удаления филиала
    const deleteFilial = async (filialId) => {
        try {
            await axios.delete(`${API_URL}/organizations/filialdelete/${filialId}`);
            setFilials(filials.filter(filial => filial._id !== filialId)); // Обновляем список филиалов
        } catch (error) {
            console.error('Ошибка при удалении филиала', error);
        }
    };

    // Функция для получения сотрудников филиала
    const fetchEmployeesForFilial = async (filialId) => {
        try {
            const response = await axios.get(`${API_URL}/organizations/sotrudniki`, { params: { filialId } });
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении сотрудников филиала', error);
            return [];
        }
    };

    const handleScheduleChange = (e) => {
        const { name, value } = e.target;
        const [day, timePoint] = name.split("-");
    };

    const [filialName, setFilialName] = useState('');
    const [filialAddress, setFilialAddress] = useState('');
    const [filialPhone, setFilialPhone] = useState('');

    const addFilial = () => {
        axios.post(`${API_URL}/organizations/addfilial`, {
            nameFilial: filialName,
            addresFilial: filialAddress,
            phoneFilial: filialPhone,
            orgID: id
        })
            .then(response => {
                // Обновляем список филиалов
                setFilials([...filials, response.data]);
                // Закрыть форму добавления
                setShowAddFilial(false);
                // Сбросить данные о новом филиале
                setFilialName('');
                setFilialAddress('');
                setFilialPhone('');
            })
            .catch(error => {
                console.error('Ошибка при добавлении филиала', error);
            });
    };

    const addFilialModal = (
        <Modal
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
            className="bg-white p-6 rounded-lg shadow-md w-1/2 max-w-md"
            isOpen={showAddFilial}
        >
            <div className="modal-content">
                <h2 className="text-lg font-bold mb-4">Добавить филиал</h2>
                <form>
                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-name">Название филиала</label>
                        <input
                            type="text"
                            id="filial-name"
                            placeholder="Название филиала"
                            value={filialName}
                            onChange={(e) => setFilialName(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-address">Адрес филиала</label>
                        <input
                            type="text"
                            id="filial-address"
                            placeholder="Адрес филиала"
                            value={filialAddress}
                            onChange={(e) => setFilialAddress(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-phone">Телефон филиала</label>
                        <input
                            type="text"
                            id="filial-phone"
                            placeholder="Телефон филиала"
                            value={filialPhone}
                            onChange={(e) => setFilialPhone(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div className='flex justify-between gap-2'>
                        <button onClick={addFilial} className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded">
                            Добавить филиал
                        </button>
                        <button onClick={() => setShowAddFilial(false)} className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded">
                            Отменить
                        </button>
                    </div>
                </form>
            </div>
        </Modal>

    );

    // Функция для добавления нового сотрудника
    // Функция для добавления нового сотрудника
    const addEmployee = async () => {
        try {
            const response = await axios.post(`${API_URL}/organizations/sotrudnikadd`, {
                userID: userID,
                dolgnost: position,
                orgID: id,
                filialID: selectedFilialID,
                sotrudnikRole: employeeRole
            });

            axios.get(`${API_URL}/organizations/sotrudniki`, { params: { id } }) // Выполните GET-запрос к API
                .then(response => {
                    setSotrudniki(response.data); // Сохраните полученные данные в состоянии
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
            // Закрыть форму добавления
            setModalIsOpen(false);
            // Сбросить выбранного пользователя и должность
            setSelectedUser(null);
            setPosition('');
        } catch (error) {
            console.error('Ошибка при добавлении сотрудника', error);
        }
    };

    const deleteEmployee = (sotrudnikId) => {
        axios.delete(`${API_URL}/organizations/sotrudnikdelete/${sotrudnikId}`)
            .then(() => {
                setSotrudniki(sotrudniki.filter(sotrudnik => sotrudnik._id !== sotrudnikId));
            })
            .catch(error => {
                console.error('Ошибка при удалении сотрудника', error);
            });
    };

    // JSX код для добавления сотрудника
    const addEmployeeModal = (
        <Modal
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
            className="bg-white p-6 rounded-lg shadow-md w-1/2 max-w-md"
            isOpen={showAddEmployee}>
            <div className="modal-content">
                <button className="close" onClick={() => setModalIsOpen(false)}>X</button>
                <div>
                    Телефон пользователя:
                </div>
                <input
                    type="text"
                    placeholder="Телефон пользователя"
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Должность"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                />
                {/* Поле для выбора роли сотрудника */}
                <select
                    value={employeeRole}
                    onChange={(e) => setEmployeeRole(e.target.value)}
                >
                    <option value="">Выбрать роль</option>
                    {roles.map((role) => (
                        <option key={role} value={role}>{role}</option>
                    ))}
                </select>

                {/* Поле для выбора филиала */}
                <select
                    value={selectedFilialID || ''}
                    onChange={(e) => setSelectedFilialID(e.target.value)}
                >
                    <option value="">Выбрать филиал</option>
                    {filials.map((filial) => (
                        <option key={filial._id} value={filial._id}>
                            {filial.nameFilial}
                        </option>
                    ))}
                </select>
                {/* Кнопка добавления сотрудника */}
                <button onClick={addEmployee}>Добавить сотрудника</button>
            </div>
        </Modal>
    );

    // Фильтрация организаций по категории и поиск
    const filteredSotrudniki = sotrudniki.filter(sotrudnik => {
        const isSearchMatch = sotrudnik.userID.familia.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sotrudnik.userID.imya.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sotrudnik.userID.otchestvo.toLowerCase().includes(searchQuery.toLowerCase());
        return isSearchMatch;
    });
    // Фильтрация организаций по категории и поиск
    const filteredFilials = filials.filter(filial => {
        const isSearchMatch = filial.nameFilial.toLowerCase().includes(searchQuery.toLowerCase());
        return isSearchMatch;
    });

    // Анимация для страницы
    const pageMotion = {
        initial: { opacity: 0, x: 50, display: 'flex', width: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', width: '100%', display: 'flex', flex: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, width: '90%', display: 'flex', transition: { duration: 0.3 } }
    };

    // Обработчик изменения запроса поиска
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-0 md:p-4 rounded-lg bg-gray-100 min-h-screen">
            <div className="p-0 md:p-2 w-full mx-auto rounded-lg shadow-sm mt-4 md:mt-0">
                <div className="flex items-center gap-2 mb-2 p-2 rounded-lg">
                    <button onClick={() => navigate(-1)} className="py-2 px-4 border-none cursor-pointer rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md">
                        ← Назад
                    </button>
                    <div className="flex items-center relative text-gray-900 p-2 rounded-lg text-xl font-bold">
                        {organization.nameOrg}
                    </div>
                </div>

                <div className="flex flex-col justify-between gap-2 flex-wrap">
                    <div className='flex flex-row p-2 justify-between'>
                        <div className="mt-2 text-sm text-gray-700">
                            <div>ИНН: {organization.innOrg}</div>
                            <div>Телефон: {organization.phoneOrg}</div>
                            <div>Электронная почта: {organization.emailOrg}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-2 flex-wrap">
                    <div className="mt-2 text-sm p-2 flex flex-row text-gray-700 gap-2">
                        <div>
                            Адрес:
                        </div>
                        <div className="flex flex-wrap">
                            {organization.addressOrg}
                        </div>
                    </div>
                </div>
                <div className="p-0 md:p-6 pt-2 bg-gray-100 rounded-lg shadow-lg items-center">
                    <h2 className="flex flex-1 text-center items-center relative text-gray-900 p-2 rounded-lg text-xl font-bold">
                        Филиалы
                    </h2>
                    <ul className="list-none mb-0">
                        {filteredFilials.map(filial => (
                            <li key={filial._id} className="flex flex-col mb-4 p-4 bg-white rounded-lg shadow-sm transition duration-200 ease-in-out hover:shadow-md">
                                <div className="flex justify-between mb-2">
                                    <h3 className="text-lg font-bold text-gray-800">{filial.nameFilial}</h3>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-1/2 pr-4">
                                        <h3 className="text-sm font-bold mb-1">Контакты</h3>
                                        <p className="text-sm text-gray-600">{filial.phoneFilial}</p>
                                        <p className="text-sm text-gray-600">{filial.addresFilial}</p>
                                    </div>
                                </div>
                                <div className="flex justify-between mt-4">
                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => deleteFilial(filial._id)}
                                    >
                                        Удалить филиал
                                    </button>
                                    <button
                                        className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                                        onClick={async () => {
                                            const employees = await fetchEmployeesForFilial(filial._id);
                                            setShowEmployees(prev => ({ ...prev, [filial._id]: employees }));
                                        }}
                                    >
                                        Показать сотрудников филиала
                                    </button>
                                </div>

                                {/* Отображение сотрудников филиала */}
                                {showEmployees[filial._id] && (
                                    <div className="mt-2">
                                        <h4 className="font-bold">Сотрудники:</h4>
                                        <ul>
                                            {showEmployees[filial._id].map(employee => (
                                                <li key={employee._id} className="text-sm text-gray-600">
                                                    {employee.userID.familia} {employee.userID.imya} {employee.userID.otchestvo}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                        <button className="py-2 px-4 border-none cursor-pointer rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md" onClick={() => setShowAddFilial(true)}>
                            Добавить филиал
                        </button>
                        <button className="py-2 px-4 border-none cursor-pointer rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md" onClick={() => setShowAddEmployee(true)}>
                            Добавить сотрудника
                        </button>
                    </ul>
                </div>

                {addEmployeeModal}
                {addFilialModal}
            </div>
        </motion.div>
    );
};

export default observer(OrganizationDetails);