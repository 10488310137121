import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck, faPlay, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Card = ({ data, onClick, onUpdateStatus, onAddComment }) => {
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [newDate, setNewDate] = useState(moment(data.scheduledPickupDate).add(1, 'days').format('YYYY-MM-DD'));

  const handleAction = (actionType, checkComment = true) => {
    if (checkComment && comment.trim() === '') {
      alert('Комментарий не может быть пустым');
      return;
    }
    onAddComment(data._id, comment, 'user');
    onUpdateStatus(data._id, actionType, newDate);
    setTransferModalOpen(false);
    setCancelModalOpen(false);
  };

  const statusStyles = {
    'Закрыта': 'bg-gray-200 text-green-600',
    'Новая': 'bg-green-200 text-red-600',
    'В работе': 'bg-yellow-200 text-yellow-600',
    'Перенос': 'bg-blue-200 text-blue-600',
  };

  return (
    <div className={`rounded-lg shadow-lg p-4 mb-4 bg-white transition-transform transform hover:scale-105 hover:shadow-xl cursor-pointer`}>
      <div className="flex items-center justify-between mb-4">
        <span className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${statusStyles[data.status] || 'bg-gray-200 text-gray-800'}`}>
          {data.type || 'Не указано'}
        </span>
        <div className="text-gray-800 text-sm">
          <p>Создано: <span className="font-semibold">{moment(data.createdAt).format('DD.MM.YYYY')}</span></p>
          <p>Выезд: <span className="font-semibold">{moment(data.scheduledPickupDate).format('DD.MM.YYYY')}</span></p>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-sm">Автор: <span className="font-semibold">{data.name || 'Не указано'}</span></p>
        <p className="text-sm">Телефон: <span className="font-semibold">{data.phone || 'Не указано'}</span></p>
        <p className="text-sm">Адрес: <span className="font-semibold">{data.address || 'Не указано'}</span></p>
        <p className="text-sm">Описание: <span className="font-semibold">{data.description || 'Не указано'}</span></p>
        {data.otv && data.otv.imya && <p className="text-sm">Ответственный: <span className="font-semibold">{data.otv.imya}</span></p>}
      </div>

      <div className="mt-4 flex justify-around">
        {(data.status === 'Новая' || data.status === 'Перенос') && (
          <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-500 transition" onClick={() => handleAction('В работе', false)}>
            <FontAwesomeIcon icon={faPlay} size="lg" />
          </button>
        )}
        {data.status === 'В работе' && (
          <>
            <button className="bg-green-600 text-white p-2 rounded hover:bg-green-500 transition" onClick={() => handleAction('Выполнено')}>
              <FontAwesomeIcon icon={faCheck} size="lg" />
            </button>
            <button className="bg-yellow-600 text-white p-2 rounded hover:bg-yellow-500 transition" onClick={() => setTransferModalOpen(true)}>
              <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </button>
          </>
        )}
        <button className="bg-red-600 text-white p-2 rounded hover:bg-red-500 transition" onClick={() => setCancelModalOpen(true)}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <button className="bg-gray-600 text-white p-2 rounded hover:bg-gray-500 transition" onClick={onClick}>
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
        </button>
      </div>

      {/* Модальное окно для переноса */}
      <Modal isOpen={isTransferModalOpen} onRequestClose={() => setTransferModalOpen(false)} className="fixed inset-0 flex items-center justify-center z-50" overlayClassName="fixed inset-0 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-xl font-semibold mb-4">Перенос заявки</h2>
          <textarea className="border border-gray-300 rounded w-full p-2 mb-4" placeholder="Комментарий" value={comment} onChange={(e) => setComment(e.target.value)} />
          <input type="date" className="border border-gray-300 rounded w-full p-2 mb-4" value={newDate} onChange={(e) => setNewDate(e.target.value)} />
          <div className="flex justify-end">
            <button className="bg-blue-600 text-white px-4 py-2 rounded mr-2 hover:bg-blue-500 transition" onClick={() => handleAction('Перенос')}>Подтвердить перенос</button>
            <button className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-200 transition" onClick={() => setTransferModalOpen(false)}>Отмена</button>
          </div>
        </div>
      </Modal>

      {/* Модальное окно для отмены */}
      <Modal isOpen={isCancelModalOpen} onRequestClose={() => setCancelModalOpen(false)} className="fixed inset-0 flex items-center justify-center z-50" overlayClassName="fixed inset-0 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-xl font-semibold mb-4">Отмена заявки</h2>
          <textarea className="border border-gray-300 rounded w-full p-2 mb-4" placeholder="Комментарий" value={comment} onChange={(e) => setComment(e.target.value)} />
          <div className="flex justify-end">
            <button className="bg-red-600 text-white px-4 py-2 rounded mr-2 hover:bg-red-500 transition" onClick={() => handleAction('Отмена')}>Подтвердить отмену</button>
            <button className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-200 transition" onClick={() => setCancelModalOpen(false)}>Отмена</button>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default Card;
