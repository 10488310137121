import React from 'react';
import Card from './Card';

const Column = ({ title, data, onClick, onUpdateStatus, onAddComment }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col w-full border border-gray-200">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="flex flex-col gap-2">
        {data.length > 0 ? (
          data.map((card) => (
            <div key={card._id}>
              <Card data={card} onClick={() => onClick(card)} onUpdateStatus={onUpdateStatus} onAddComment={onAddComment} // Передаем функцию
              />
            </div>
          ))
        ) : (
          <p className="text-gray-500">Нет доступных заявок</p>
        )}
      </div>
    </div>
  );
};

export default Column;
