import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import { motion } from 'framer-motion';
import ReactLoading from "react-loading";
import Column from './Column';
import CardModal from './CardModal';
import { useParams, useNavigate } from 'react-router-dom';

const KurierPage = ({ toggleSidebar }) => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, display: 'flex', width: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', width: '100%', display: 'flex', flex: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, width: '90%', display: 'flex', transition: { duration: 0.3 } }
  };
  const { store } = useContext(Context);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [columns, setColumns] = useState({
    work: [],
    today: [],
    tomorrow: [],
    later: [],
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeColumn, setActiveColumn] = useState('work'); // Состояние для активной колонки 
  const fetchData = async () => {
    try {
      const userRole = store.user.role;

      const response = await fetch(`${API_URL}/zayssitecur/zayssitecurall`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.token}`,
          'Role': userRole
        }
      });
      
      const jsonData = await response.json();
      console.log(jsonData.zaycur);
      organizeData(jsonData.zaycur);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const organizeData = (data) => {
    const todayCards = data.filter(card => card.status !== 'В работе' && card.status !== 'Выполнено' && card.status !== 'Отмена' && new Date(card.scheduledPickupDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
    const tomorrowCards = data.filter(card => card.status !== 'В работе' && card.status !== 'Выполнено' && card.status !== 'Отмена' && new Date(card.scheduledPickupDate).setHours(0, 0, 0, 0) === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0));
    const laterCards = data.filter(card => card.status !== 'В работе' && card.status !== 'Выполнено' && card.status !== 'Отмена' && new Date(card.scheduledPickupDate) > new Date(new Date().setDate(new Date().getDate() + 1)));

    setColumns({
      work: data.filter(card => card.status === 'В работе'),
      today: todayCards,
      tomorrow: tomorrowCards,
      later: laterCards,
    });
  };

  useEffect(() => {
    if (isUserMenuOpen) {
      setIsUserMenuOpen(false);
  }
    fetchData();
  }, [store.user.id]);

  const handleCardClick = (cardData) => {
    setSelectedCard(cardData);
  };

  const handleAddComment = async (id, text, author) => {
    // Проверяем, что текст комментария не пустой
    if (!text || text.trim() === '') {
      console.warn('Комментарий не может быть пустым. Запрос не будет отправлен.');
      return; // Не отправляем запрос, если текст пустой
    }
  
    try {
      const response = await fetch(`${API_URL}/zayssitecur/addComment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, text, author: store.user.id, type: 'user' }),
      });
  
      if (!response.ok) {
        throw new Error('Ошибка при добавлении комментария');
      }
  
      fetchData();
    } catch (error) {
      console.error('Ошибка при добавлении комментария:', error);
    }
  };
  

  const handleCreateTaskClick = () => {
    navigate('/create-task');
  };

  const handleUpdateStatus = async (id, newStatus, newDate) => {
    try {
      const response = await fetch(`${API_URL}/zayssitecur/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, status: newStatus, otv: store.user.id, scheduledPickupDate: newDate }),
      });
      if (!response.ok) {
        throw new Error('Ошибка при обновлении статуса');
      }
      fetchData();
    } catch (error) {
      console.error('Ошибка при обновлении статуса:', error);
    }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex flex-col gap-6 w-full min-h-full max-w-full">
        <div className="flex justify-between items-center gap-4 rounded-lg shadow-lg p-2 mb-2 bg-white">
          <button onClick={() => navigate(-1)} className="bg-blue-500 text-white px-4 py-2 rounded">
            Назад </button>
          <h1 className="text-xl font-bold">Выезд курьера/инженера</h1>
          <button onClick={handleCreateTaskClick} className="bg-green-500 text-white px-4 py-2 rounded">
            Создать задание </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center w-full h-64">
            <ReactLoading type="spinningBubbles" color="#103d773b" height={60} width={60} />
          </div>
        ) : (
          <div className="flex flex-col gap-4 max-w-full">
            {/* Кнопки для переключения между колонками на мобильных устройствах */}
            <div className="flex justify-around mb-4 md:hidden">
              <button onClick={() => setActiveColumn('work')}
                className={`p-2 rounded transition-transform duration-200 ${activeColumn === 'work' ? 'bg-blue-500 text-white font-bold transform scale-110' : 'bg-gray-300 text-black transform scale-75 text-sm'}`}
              >
                В работе </button>
              <button onClick={() => setActiveColumn('today')}
                className={`p-2 rounded transition-transform duration-200 ${activeColumn === 'today' ? 'bg-blue-500 text-white font-bold transform scale-110' : 'bg-gray-300 text-black transform scale-75 text-sm'}`}
              >
                На сегодня </button>
              <button onClick={() => setActiveColumn('tomorrow')}
                className={`p-2 rounded transition-transform duration-200 ${activeColumn === 'tomorrow' ? 'bg-blue-500 text-white font-bold transform scale-110' : 'bg-gray-300 text-black transform scale-75 text-sm'}`}
              >
                Завтра </button>
              <button onClick={() => setActiveColumn('later')}
                className={`p-2 rounded transition-transform duration-200 ${activeColumn === 'later' ? 'bg-blue-500 text-white font-bold transform scale-110' : 'bg-gray-300 text-black transform scale-75 text-sm'}`}
              >
                Позднее
              </button>
            </div>


            {/* Отображение колонок в зависимости от размера экрана */}
            <div className="flex flex-col md:flex-row gap-4 max-w-full">
              {/* На мобильных устройствах отображается только активная колонка */}
              <div className="md:hidden w-full md:w-1/4 gap-4">
                {activeColumn === 'work' && (
                  <Column title="В работе"
                    data={columns.work}
                    onClick={handleCardClick}
                    onUpdateStatus={handleUpdateStatus}
                    onAddComment={handleAddComment}
                  />
                )}
                {activeColumn === 'today' && (
                  <Column title="На сегодня"
                    data={columns.today}
                    onClick={handleCardClick}
                    onUpdateStatus={handleUpdateStatus}
                    onAddComment={handleAddComment}
                  />
                )}
                {activeColumn === 'tomorrow' && (
                  <Column title="Завтра"
                    data={columns.tomorrow}
                    onClick={handleCardClick}
                    onUpdateStatus={handleUpdateStatus}
                  />
                )}
                {activeColumn === 'later' && (
                  <Column title="Позднее"
                    data={columns.later}
                    onClick={handleCardClick}
                    onUpdateStatus={handleUpdateStatus}
                    onAddComment={handleAddComment}
                  />
                )}
              </div>

              {/* На компьютерах отображаются все колонки, кроме "В работе" */}
              <div className="hidden md:flex md:flex-row md:w-full gap-4">
                <Column title="В работе"
                  data={columns.work}
                  onClick={handleCardClick}
                  onUpdateStatus={handleUpdateStatus}
                  onAddComment={handleAddComment}
                />
                <Column title="На сегодня"
                  data={columns.today}
                  onClick={handleCardClick}
                  onUpdateStatus={handleUpdateStatus}
                  onAddComment={handleAddComment}
                />
                <Column title="Завтра"
                  data={columns.tomorrow}
                  onClick={handleCardClick}
                  onUpdateStatus={handleUpdateStatus}
                />
                <Column title="Позднее"
                  data={columns.later}
                  onClick={handleCardClick}
                  onUpdateStatus={handleUpdateStatus}
                  onAddComment={handleAddComment}
                />
              </div>
            </div>
          </div>
        )}
        {selectedCard && <CardModal card={selectedCard} onClose={() => setSelectedCard(null)} />}
      </div>
    </motion.div>
  );
};

export default observer(KurierPage);
