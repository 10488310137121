// src/pages/TicketPage.js
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { success, errors } from '../../alerts/alerting_service';
import { observer } from 'mobx-react-lite';
import { useNotification } from '../../../NotificationContext';

const TicketAdd = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('feature');
    const [isLoading, setIsLoading] = useState(true);
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const [notify, setNotify] = useState(false);

    useEffect(() => {
        const fetchTickets = async () => {
            setIsLoading(true);
            try {
                const userRole = store.user.role;
                await axios.get(`${API_URL}/tickets`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.token}`,
                        'Role': userRole
                    }
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchTickets();
    }, [store.user.role]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let subscribers; // Initialize subscribers variable
    
        if (notify) {
            subscribers = store.user.id; // Assign value if notify is true
        } else {
            subscribers = []; // Or assign a default value if notify is false
        }
    
        const newTicket = { 
            title, 
            description, 
            type, 
            author: store.user.id, 
            subscribers 
        };
    
        await axios.post(`${API_URL}/tickets`, newTicket);
        addNotification('Тикет успешно создан', 'success');
        navigate(-1); // Переход обратно к списку тикетов после создания
    };

    const handleCancel = () => {
        navigate(-1); // Navigate to the ticket list page or any desired route
    };


    if (isLoading) {
        return (
            <div className="flex justify-center items-center w-full min-h-full">
                <ReactLoading type="spinningBubbles" color="#103d773b" height={50} width={50} />
            </div>
        );
    }

    return (
        <div className="p-4 w-full h-full">
            <h1 className="text-2xl font-bold mb-4">Создание тикета</h1>
            <form onSubmit={handleSubmit} className="w-full bg-white shadow-md rounded p-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                        Название тикета
                    </label>
                    <input
                        type="text"
                        id="title"
                        placeholder="Введите название тикета"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        Описание
                    </label>
                    <textarea
                        id="description"
                        placeholder="Введите описание тикета"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                        Тип тикета
                    </label>
                    <select
                        id="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="feature">Доработка</option>
                        <option value="bug">Ошибка</option>
                    </select>
                    <label>
                        <input type="checkbox" checked={notify} onChange={() => setNotify(!notify)} />
                        Уведомлять об изменениях
                    </label>
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 transition duration-200">
                    Создать тикет
                </button>
                <button type="button" onClick={handleCancel} className="bg-gray-500 text-white p-2 rounded hover:bg-gray-700 transition duration-200">
                    Отмена
                </button>
            </form>
        </div>
    );
};

export default observer(TicketAdd);
