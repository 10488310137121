import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { Link, useParams, useNavigate } from 'react-router-dom';
import './card.css';
import TextareaAutosize from 'react-textarea-autosize';

const Card = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, transition: { duration: 0.3 } }
  };

  const [raboti, setRaboti] = useState([]);
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [zremData, setZremData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchZapSSiteById = async () => {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          const response = await axios.get(`${API_URL}/zrem/zremById/${id}`);
          setZremData(response.data);
          setRaboti(response.data.raboti);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      }, 500);
    };
    fetchZapSSiteById();
  }, [id]);

  const statusColors = {
    'Принят в ремонт': 'bg-emerald-500',
    'Требует согласования': 'bg-emerald-800',
    'Согласовано': 'bg-green-600',
    'Необходимо заказать ЗИП': 'bg-red-600',
    'Ожидает ЗИП': 'bg-red-400',
    'ЗИП Получен': 'bg-green-700',
    'Оповестить клиента': 'bg-yellow-400',
    'К выдаче': 'bg-gray-600',
    'Выдан покупателю': 'bg-gray-300 text-gray-600',
    default: 'bg-gray-50',
  };

  const getStatusColor = (status) => statusColors[status] || statusColors.default;

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-0 md:p-4 rounded-lg bg-gray-100 min-h-screen w-full">
      <div className="flex flex-col gap-6 w-full min-h-full max-w-full p-0 md:p-2 mx-auto rounded-lg shadow-sm mt-4 md:mt-0">
  {isLoading ? (
    <div className="flex justify-center items-center w-full min-h-full">
      <ReactLoading type="spinningBubbles" color="#103d773b" height={50} width={50} />
    </div>
  ) : (
    <div className='flex w-full max-w-full flex-col gap-4'>
      <div className='bg-gray-900 text-white p-4 rounded-lg shadow-md flex-wrap'>
        <div className="flex items-center space-x-4 text-white">
          <button className='px-4 py-2 bg-gray-700 text-white rounded-lg shadow-md hover:bg-gray-600 transition'
            onClick={() => navigate(-1)}
          >
            Назад </button>
          <div className={`w-4 h-4 rounded-full ${getStatusColor(zremData?.status)}`}></div>
          <div className="flex-1">
            <div className="text-xs text-white">{zremData?.status}</div>
            <div className="text-base font-semibold text-white">{zremData?.numberremonta}</div>
          </div>
          <div className="mt-2 text-xs text-white">
            <div>Принят: {zremData?.datapriemki}</div>
            <div>Изменен: {zremData?.dataizmst}</div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg shadow-md gap-4">
        <div className='text-gray-600 p-2 rounded-lg'>
          <h3 className='text-base font-semibold'>Информация о пользователе</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-xs text-gray-600">Клиент:</div>
            <div className="text-base font-semibold">{zremData?.klient}</div>
          </div>
          <div>
            <div className="text-xs text-gray-600">Телефон:</div>
            <div className="text-base font-semibold">{zremData?.phone}</div>
          </div>
          <div>
            <div className="text-xs text-gray-600">ИНН:</div>
            <div className="text-base font-semibold">{zremData?.kontrinn}</div>
          </div>
          <div>
            <div className="text-xs text-gray-600">Организация:</div>
            <div className="text-base font-semibold">{zremData?.kontr}</div>
          </div>
        </div>

        <div className='text-gray-600 p-2 rounded-lg'>
          <h3 className='text-base font-semibold'>Информация об устройстве</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-xs text-gray-600">Устройство:</div>
            <div className="text-base font-semibold">{zremData?.nomenklatura}</div>
          </div>
          <div>
            <div className="text-xs text-gray-600">Серийный номер:</div>
            <div className="text-base font-semibold">{zremData?.sernom}</div>
          </div>
        </div>

        <div>
          <div className="text-xs text-gray-600">Заявленная неисправность:</div>
          <TextareaAutosize className="w-full mt-2 p-2 border border-gray-300 rounded-lg resize-none"
            value={zremData?.neispravnost || ''} readOnly
            disabled
            rows={3}
          />
        </div>
      </div>

      <div className='bg-gray-900 text-white p-4 rounded-lg shadow-md'>
        <h3 className='text-base font-semibold'>Решение от сервисного центра</h3>
      </div>

      <div className='bg-gray-100 p-4 rounded-lg shadow-md'>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 text-left text-gray-700">Наименование работ</th>
              <th className="py-2 px-4 text-left text-gray-700">Кол-во</th>
              <th className="py-2 px-4 text-left text-gray-700">Цена</th>
              <th className="py-2 px-4 text-left text-gray-700">Сумма</th>
            </tr>
          </thead>
          <tbody>
            {raboti.map((item, index) => (
              <tr key={index} className="transition hover:bg-gray-50">
                <td className="py-2 px-4">{item.rabota}</td>
                <td className="py-2 px-4 text-center">{item.kolichestvo}</td>
                <td className="py-2 px-4 text-center">{item.tsena ? `${item.tsena} руб.` : '-'}</td>
                <td className="py-2 px-4 text-center">{item.summa ? `${item.summa} руб.` : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-full flex bg-gray-200 justify-around">
          <td colSpan="3" className="py-2 px-4 font-semibold text-gray-700">Сумма ремонта:</td>
          <td className="py-2 px-4 text-center">
            <input className="w-full p-2 border border-gray-300 rounded-md" value={zremData.sumrem ? `${zremData.sumrem} руб.` : '-'} readOnly />
          </td>
        </div>
      </div>
    </div>
  )}
</div>

    </motion.div>

  );
};

export default observer(Card);
