// CardModal.js

import React from 'react';

const CardModal = ({ card, onClose }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Информация о заявке</h2>
                <div className="space-y-2">
                    <p><strong>Телефон:</strong> {card.phone}</p>
                    <p><strong>Тип:</strong> {card.type}</p>
                    <p><strong>Имя:</strong> {card.name}</p>
                    <p><strong>Модель устройства:</strong> {card.model}</p>
                    <p><strong>Адрес:</strong> {card.address}</p>
                    <p><strong>Описание:</strong> {card.description}</p>
                    <p><strong>Дата создания:</strong> {new Date(card.createdAt).toLocaleString()}</p>
                    <p><strong>Запланированная дата забора:</strong> {new Date(card.scheduledPickupDate).toLocaleString()}</p>
                    <p><strong>Статус:</strong> {card.status}</p>
                    <p><strong>Номер индекса:</strong> {card.numindex}</p>
                    <p><strong>Ответственный:</strong> {card.response}</p>
                    <p><strong>Порядковый номер:</strong> {card.order}</p>
                    {card.otv && card.otv.imya && (
                        <p className="text-sm text-gray-600">
                            <strong>Ответственный:</strong> <span className="font-semibold">{card.otv.imya}</span>
                        </p>
                    )}
                </div>

                <h3 className="mt-4 font-bold text-lg text-gray-800">Комментарии:</h3>
                <ul className="mt-2 space-y-2">
                    {card.comments && card.comments.length > 0 ? (
                        card.comments.map((comment, index) => (
                            <li key={index} className="border-b border-gray-200 pb-2">
                                <p className="text-gray-700">
                                    <strong>Автор:</strong> {comment.author.imya} <strong>Текст:</strong> {comment.text}
                                </p>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-500">Нет комментариев</p>
                    )}
                </ul>

                <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 mt-4 rounded hover:bg-red-600 transition duration-200"
                >
                    Закрыть
                </button>
            </div>
        </div>
    );
};

export default CardModal;
