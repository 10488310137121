import React, { useContext, useEffect } from 'react';
import AuthPage from './components/AuthPage/AuthPage';
import Dashboard from './components/Dashboards/Dashboard';
import { observer } from 'mobx-react-lite';
import LoadingScreen from './components/loader/LoadingScreen';
import { motion } from 'framer-motion';
import './App.css';
import { Context } from "./index";

const App = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth();
    }
  }, [store]);

  if (store.isLoading) {
    return (
      <LoadingScreen />
    );
  }

  return (
    <div className="relative min-h-screen">
      {!store.isAuth ? <AuthPage /> : <Dashboard />}
      
    </div>
  );
};

export default observer(App);
