import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios';
import { API_URL } from '../http';

export default class Store {
    user = {};
    isAuth = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('role', user.role);
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    async login(phone, password) {
        try {
            const response = await AuthService.login(phone, password);
            localStorage.setItem('token', response.data.accessToken);
            this.setUser(response.data.user);
            this.setAuth(true);
            return response.data; // Возвращаем данные для дальнейшей обработки, если нужно 
            } catch (e) {
            console.error('Ошибка входа:', e.response?.data?.message);
            throw e; // Пробрасываем ошибку дальше для обработки в компоненте 
            }
        }

    async registration(email, phone, password) {
            try {
                const response = await AuthService.registration(email, phone, password);
                localStorage.setItem('token', response.data.accessToken);
                this.setUser(response.data.user);
                this.setAuth(true);
                return response.data; // Возвращаем данные
            } catch (e) {
                console.error('Ошибка регистрации:', e.response?.data?.message);
                throw e; // Пробрасываем ошибку 
                }
            }

    async logout() {
                try {
                    await AuthService.logout();
                    localStorage.removeItem('token');
                    this.setAuth(false);
                    this.setUser({});
                } catch (e) {
                    console.error('Ошибка выхода:', e.response?.data?.message);
                }
            }

    async checkAuth() {
                this.setLoading(true);
                try {
                    const response = await axios.get(`${API_URL}/refresh`, { withCredentials: true });
                    localStorage.setItem('token', response.data.accessToken);
                    this.setAuth(true);
                    this.setUser(response.data.user);
                } catch (e) {
                    console.error('Ошибка проверки аутентификации:', e.response?.data?.message);
                } finally {
                    this.setLoading(false);
                }
            }
        }
