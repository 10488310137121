import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../../index";
import { API_URL } from '../../../../../../http';
import axios from 'axios';
import { success, errors } from '../../../alerts/alerting_service';
import { useParams, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import './card.css';

const Card = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, transition: { duration: 0.3 } }
  };

  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [zay, setZay] = useState(null);
  const [status, setStatus] = useState('');
  const [otvet, setOtvet] = useState('');
  const [familia, setFamilia] = useState('-------');
  const [imya, setImya] = useState('-------');
  const { id } = useParams();
  const navigate = useNavigate();

  const today = new Date().toLocaleDateString("ru-RU", { day: "numeric", month: "numeric", year: "numeric" });
  const time = new Date().toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });

  useEffect(() => {
    const fetchZapSSiteById = async () => {
      setIsLoading(true);
      setTimeout(async () => {
      try {
        const response = await axios.get(`${API_URL}/zayssite/zayssiteaById/${id}`);
        const { zayssite } = response.data;
        setZay(zayssite);
        setStatus(zayssite.status);
        setOtvet(zayssite.otvet);
        fetchUserProfile(zayssite.otvsotr);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }, 500);
    };
    fetchZapSSiteById();
  }, [id]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/zayssite/zayssitesave`, {
        id, otvsotr: store.user.id, otvet, dataof: today, timeof: time, status: 'Закрыто'
      });
      const { zayssite } = response.data;
      setStatus(zayssite.status);
      setOtvet(zayssite.otvet);
      setZay(zayssite);
      fetchUserProfile(store.user.id);
      success('Сохранение успешно', 3);
    } catch (error) {
      console.error('Error saving data:', error);
      errors('Ошибка при сохранении', 3);
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      'Новая': 'bg-green-500',
      'В работе': 'bg-yellow-500',
      'Закрыто': 'bg-gray-500'
    };
    return colors[status] || 'bg-gray-200';
  };

  const fetchUserProfile = async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/loadprofileById/${userId}`);
      const { familia, imya } = response.data;
      setFamilia(familia);
      setImya(imya);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-2 bg-white shadow-lg rounded-lg w-full">
      <div className="flex w-full flex-col gap-4">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-48">
            <ReactLoading type="spinningBubbles" color="#007bff" height={50} width={50} />
          </div>
        ) : (
          <div className="space-y-2">
            <div className="p-2 rounded-lg shadow-sm">
              <div className="flex items-center space-x-4">
                <div className={`w-4 h-4 rounded-full ${getStatusColor(status)}`}></div>
                <div className="flex-1">
                  <div className="text-lg font-semibold">{zay?.name}</div>
                  <div className="text-sm text-gray-600">{zay?.phone}</div>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <div>Дата: {zay?.data}</div>
                  <div>Время: {zay?.time}</div>
                </div>
              </div>
              <div className="mt-4">
                <label className="font-semibold text-gray-600">Запрос:</label>
                <textarea className="mt-1 p-2 border rounded-lg bg-white text-gray-800 w-full" value={zay?.opisanie || ''} readOnly />
              </div>
            </div>
            <div className="bg-gray-800 text-white rounded-lg p-4 text-center">
              Решение от сервисного центра
            </div>
            <div className="p-4 rounded-lg shadow-sm">
              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Сотрудник:</div>
                  <div className="text-lg font-semibold">{familia} {imya}</div>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <div>Дата: {zay?.dataof}</div>
                  <div>Время: {zay?.timeof}</div>
                </div>
              </div>
              <div className="mt-4">
                <label className="font-semibold text-gray-600">Ответ/решение:</label>
                <textarea
                  className="mt-1 p-2 border rounded-lg bg-white text-gray-800 w-full"
                  onChange={(e) => setOtvet(e.target.value)}
                  value={otvet}
                />
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  className="px-4 py-2 bg-gray-800 text-white rounded-lg shadow hover:bg-gray-700 transition"
                  onClick={() => navigate(-1)}
                >
                  Назад
                </button>
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-500 transition"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? 'Сохранение...' : 'Сохранить'}
                </button>
              </div>

            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default observer(Card);
