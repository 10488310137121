import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { FaTrash, FaBug, FaClipboardList, FaRegStar } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useNotification } from '../../../NotificationContext';

const TicketingSystem = () => {
    const pageMotion = {
        initial: { opacity: 0, x: 50, display: 'flex', width: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', width: '100%', display: 'flex', flex: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, width: '90%', display: 'flex', transition: { duration: 0.3 } }
    };

    const { addNotification } = useNotification();
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { store } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTickets = async () => {
            setIsLoading(true);
            setTimeout(async () => {
                try {
                    const userRole = store.user.role;

                    const response = await axios.get(`${API_URL}/tickets`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.token}`,
                            'Role': userRole
                        }
                    });

                    if (Array.isArray(response.data.tickets)) {
                        setTickets(response.data.tickets);
                    } else {
                        console.error("Unexpected response format:", response.data);
                        setTickets([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setTickets([]);
                } finally {
                    setIsLoading(false);
                }
            }, 500);
        };
        fetchTickets();
    }, [store.user.role]);

    const handleDelete = async (id) => {
        await axios.delete(`${API_URL}/tickets/${id}`);
        setTickets(tickets.filter(ticket => ticket._id !== id));
        addNotification('Тикет удален', 'success');
    };

    const handleStatusChange = async (id, newStatus) => {
        await axios.put(`${API_URL}/tickets/tiketupdate/${id}`, { status: newStatus });
        setTickets(tickets.map(ticket => ticket._id === id ? { ...ticket, status: newStatus } : ticket));
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center w-full min-h-full">
                <ReactLoading type="spinningBubbles" color="#103d773b" height={50} width={50} />
            </div>
        );
    }

    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-0 md:p-4 rounded-lg bg-gray-100 min-h-screen">
            <div className="p-0 md:p-2 w-full mx-auto rounded-lg shadow-sm mt-4 md:mt-0">
                {/* Header Section */}
                <div className="p-2">
                    <div className="flex flex-col md:flex-row items-center md:justify-between gap-2">
                        <h1 className="text-2xl text-center font-bold text-gray-800">Заявки на доработки</h1>
                        <button
                            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                            onClick={() => navigate('ticketadd')} // Измените на ваш путь для TicketAdd
                        >
                            Создать
                        </button>
                    </div>
                </div>

                {/* Loading Spinner */}
                {isLoading ? (
                    <div className="flex justify-center items-center my-8">
                        <ReactLoading
                            type="spinningBubbles"
                            color="#103d77"
                            height={50}
                            width={50}
                        />
                    </div>
                ) : (
                    <div className="mt-6 space-y-4">
                        <ul className="space-y-4">
                            {tickets.map(ticket => (
                                <li key={ticket._id} className="flex relative flex-col bg-gray-50 border border-gray-200 p-4 gap-2 rounded-lg shadow-md hover:bg-white cursor-pointer transition-all transform hover:shadow-lg hover:-translate-y-1">
                                    <div className="absolute top-0 right-0 ml-auto w-12 h-12 opacity-30 rounded-t-lg flex items-center justify-center">
                                        {ticket.type === 'bug' ? (
                                            <FaBug className="text-red-600 w-full h-full" style={{ fontSize: '4rem' }} />
                                        ) : (
                                            <FaClipboardList className="text-blue-600 w-full h-full" style={{ fontSize: '4rem' }} />
                                        )}
                                    </div>
                                    <div className="flex relative items-center w-full gap-2">
                                        {/* Иконка статуса с текстом */}
                                        <div className="flex items-center gap-2">
                                            {ticket.status === 'open' ? (
                                                <span className="bg-blue-100 text-blue-800 text-sm font-semibold px-2.5 py-0.5 rounded">
                                                    <FaRegStar className="inline-block text-blue-600 align-middle" /> Новая </span>
                                            ) : ticket.status === 'in_progress' ? (
                                                <span className="bg-yellow-100 text-yellow-800 text-sm font-semibold px-2.5 py-0.5 rounded">
                                                    🔄 В процессе
                                                </span>
                                            ) : ticket.status === 'completed' ? (
                                                <span className="bg-green-100 text-green-800 text-sm font-semibold px-2.5 py-0.5 rounded">
                                                    ✅ Выполнено
                                                </span>
                                            ) : (
                                                <span className="bg-red-100 text-red-800 text-sm font-semibold px-2.5 py-0.5 rounded">
                                                    ❌ Ошибка
                                                </span>
                                            )}
                                        </div>
                                        <h3 className="text-xl font-bold text-gray-800">{ticket.title}</h3>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-sm text-gray-600">{ticket.description}</p>
                                    </div>
                                    <div className="ml-auto flex items-center gap-2">
                                        {store.user.role === 'dev' && (
                                            <div className="flex gap-2">
                                                <button onClick={() => handleStatusChange(ticket._id, 'in_progress')} className="text-sm text-blue-600 hover:text-blue-800 transition">
                                                    В процессе </button>
                                                <button onClick={() => handleStatusChange(ticket._id, 'completed')} className="text-sm text-green-600 hover:text-green-800 transition">
                                                    Завершено </button>
                                            </div>
                                        )}
                                        <button onClick={() => handleDelete(ticket._id)} className="ml-4 text-sm text-red-600 hover:text-red-800 transition flex items-center">
                                            <FaTrash className="mr-1" />
                                            Удалить
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default observer(TicketingSystem);
