import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { API_URL } from '../../../../../http';
import { success, errors } from '../../alerts/alerting_service';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const UserListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pageMotion = {
    initial: { opacity: 0, x: 50, width: '90%' },
    animate: { opacity: 1, x: 0, width: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, width: '90%', transition: { duration: 0.3 } }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/users`);
        setUsers(response.data);
        setFilteredUsers(response.data); // Initialize filtered users
        success('Список пользователей загружен', 3);
      } catch (error) {
        console.error('Error fetching users:', error);
        errors('Ошибка при загрузке списка пользователей', 3);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      if (searchQuery.trim() === '') {
        setFilteredUsers(users);
      } else {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = users.filter(user =>
          `${user.imya} ${user.familia} ${user.otchestvo} ${user.email} ${user.phone}`
            .toLowerCase()
            .includes(lowercasedQuery)
        );
        setFilteredUsers(filtered);
      }
    };

    handleSearch();
  }, [searchQuery, users]);

  const handleCardClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-0 md:p-4 rounded-lg bg-gray-100 min-h-screen">
      <div className="p-0 md:p-2 w-full mx-auto rounded-lg shadow-sm mt-4 md:mt-0">
        {isLoading ? (
          <div className="flex items-center justify-center h-full w-full flex-col">
            <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
            <h2>Загрузка, пожалуйста подождите...</h2>
          </div>
        ) : (
          <div className="p-2">

            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-semibold text-gray-900">Пользователи</h1>
              <div className="flex space-x-2">
                <input
                  type="text"
                  placeholder="Поиск по всем полям..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
                />
              </div>
            </div>
            <div className="space-y-4">
              {filteredUsers.map(user => (
                <div
                  key={user.id}
                  className="bg-white p-4 border border-gray-200 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow"
                  onClick={() => handleCardClick(user)}
                >
                  <div>
                    <h3 className="text-xl font-semibold">{user.familia} {user.imya} {user.otchestvo}</h3>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <div>
                      {user.isActivated ? (
                        <div className="flex items-center text-green-500">
                          <FaCheckCircle />

                        </div>
                      ) : (
                        <div className="flex items-center text-red-500">
                          <FaTimesCircle />

                        </div>
                      )}
                      {user.isPhone ? (
                        <div className="flex items-center text-green-500 mt-1">
                          <FaCheckCircle />

                        </div>
                      ) : (
                        <div className="flex items-center text-red-500 mt-1">
                          <FaTimesCircle />

                        </div>
                      )}
                    </div>
                    <div>
                      <p className="text-gray-600">Email: {user.email}</p>
                      <p className="text-gray-600">Телефон: {user.phone}</p>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isModalOpen && selectedUser && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
              <h2 className="text-xl font-semibold mb-4">Информация о пользователе</h2>
              <p className="mb-2"><strong>Имя:</strong> {selectedUser.imya} {selectedUser.familia}</p>
              <p className="mb-2"><strong>Email:</strong> {selectedUser.email}</p>
              <p className="mb-2"><strong>Телефон:</strong> {selectedUser.phone}</p>
              <p className="mb-2"><strong>Статус активации:</strong> {selectedUser.isActivated ? 'Активирован' : 'Не активирован'}</p>
              <p className="mb-2"><strong>Телефон подтвержден:</strong> {selectedUser.isPhone ? 'Да' : 'Нет'}</p>
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 bg-gray-500 text-white rounded-lg"
                >
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default UserListPage;
