import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../index";
import { API_URL } from '../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';

const ZayavkiSSite = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50, display: 'flex', width: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', width: '100%', display: 'flex', flex: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, width: '90%', display: 'flex', transition: { duration: 0.3 } }
  };
  const [selectedZayavka, setSelectedZayavka] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [zayssites, setZayavki] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('date');

  const { store } = useContext(Context);

  useEffect(() => {
    const fetchAllZapSSite = async (activeTab) => {
      setIsLoading(true);
      setTimeout(async () => {
        try {

          const response = await axios.get(`${API_URL}/zrem/getzremall?category=${activeTab}&role=${store.user.role}&klient=${store.user.phone}`);
          setZayavki(response.data.zayssite);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      }, 500);
    };
    fetchAllZapSSite(activeTab);
  }, [store.user.id, activeTab]);

  const filteredZayavki = zayssites.filter((zay) => {
    const isCategoryMatch = activeTab === 'all' || zay.status === activeTab;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const isSearchMatch = Object.values(zay).some(value => {
      return typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchQuery);
    });
    return isCategoryMatch && isSearchMatch;
  });

  const sortedZayavki = filteredZayavki.sort((a, b) => {
    if (sortBy === 'datapriemki') {
      return new Date(b.datapriemki.split('.').reverse().join('-')) - new Date(a.datapriemki.split('.').reverse().join('-'));
    } else if (sortBy === 'numberremonta') {
      return a.numberremonta.localeCompare(b.numberremonta);
    } else if (sortBy === 'status') {
      return a.status.localeCompare(b.status);
    }
  });

  const handleTabChange = (category) => {
    setActiveTab(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortBy(sortType);
  };

  const navigate = useNavigate();

  const handleCardClick = (zayavka) => {
    setSelectedZayavka(zayavka);
    navigate(`/card/${zayavka._id}`);
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'Принят в ремонт': 'emerald-500',
      'Требует согласования': 'emerald-800',
      'Согласовано': 'green-600',
      'Необходимо заказать ЗИП': 'red-600',
      'Ожидает ЗИП': 'red-400',
      'ЗИП Получен': 'green-700',
      'Оповестить клиента': 'yellow-400',
      'К выдаче': 'gray-600',
      'Выдан покупателю': 'gray-300 text-gray-600',
    };
    return statusColors[status] || 'gray-50';
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-0 md:p-4 rounded-lg bg-gray-100 min-h-screen">
      <div className="flex flex-col gap-6 w-full min-h-full max-w-full p-0 md:p-2 mx-auto rounded-lg shadow-sm mt-4 md:mt-0">
        <div className="flex flex-col md:flex-row items-center md:items-center justify-between mb-6 flex-wrap">
          <h1 className="text-3xl font-bold text-gray-900 mb-4 md:mb-0">
            Ремонты
          </h1>
          <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="text-sm text-gray-600 mb-2 md:mb-0">Выберите статус ремонта:</div>
            <div className="relative w-full md:w-64">
              <Menu>
                <MenuButton className="w-full md:w-64 inline-flex items-center gap-2 rounded-md bg-slate-900 py-2 px-4 text-sm font-semibold text-white shadow-inner focus:outline-none hover:bg-slate-700 justify-between">
                  {activeTab === 'all' ? 'Все' : activeTab}
                  <ChevronDownIcon className="w-5 h-5 text-white" />
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 w-48 mt-2 origin-top-right rounded-xl border border-white/5 bg-slate-800 p-2 text-sm text-white z-50">
                    {['Принят в ремонт', 'Требует согласования', 'Согласовано', 'Необходимо заказать ЗИП', 'Ожидает ЗИП', 'ЗИП Получен', 'Оповестить клиента', 'К выдаче', 'all', 'Выдан покупателю'].map(status => (
                      <MenuItem key={status}>
                        <button className="w-full py-2 px-4 text-left hover:bg-white/10" onClick={() => handleTabChange(status)}>
                          {status === 'all' ? 'Все' : status}
                        </button>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
            <div className="relative w-full md:w-64">
              <input
                className='w-full md:w-64 p-2 rounded-lg border border-slate-300 text-slate-900'
                type="text"
                placeholder="Поиск..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-64">
            <ReactLoading type="spinningBubbles" color="#103d773b" height={60} width={60} />
          </div>
        ) : (
          <div className="flex flex-col gap-4 max-w-full">
            {sortedZayavki.map((zay) => (
              <div
                key={zay._id}
                className={`border border-${getStatusColor(zay.status)} p-4 rounded-lg bg-gray-50 shadow-sm hover:bg-white cursor-pointer transition-all transform hover:shadow-lg hover:-translate-y-1`}
                onClick={() => navigate(`cardrem/${zay._id}`)}
              >
                <div className="flex items-center space-x-1 flex-wrap mb-1">
                  <div className={`w-4 h-4 rounded-full bg-${getStatusColor(zay.status)}`}></div>
                  <div className="flex-1">
                    <div className="text-sm text-gray-600">{zay.status}</div>
                    <div className="text-lg font-semibold">{zay.numberremonta}</div>
                  </div>
                  <div className="flex-1">
                    <div className="text-sm text-gray-600">{zay.klient}</div>
                    <div className="text-lg font-semibold">{zay.kontr}</div>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <div>Принят: {zay.datapriemki}</div>
                    <div>Изменен: {zay.dataizmst}</div>
                  </div>
                </div>

                <div className="flex items-center space-x-1 flex-wrap mt-1">
                  <div className="flex-1">
                    <div className="text-sm text-gray-600">Устройство:</div>
                    <div className="text-lg font-semibold">{zay.nomenklatura}</div>
                  </div>
                  <div className="flex-1">
                    <div className="text-sm text-gray-600">Серийный номер:</div>
                    <div className="text-lg font-semibold">{zay.sernom}</div>
                  </div>
                  <div className="flex-1">
                    <div className="text-sm text-gray-600">Стоимость работ:</div>
                    <div className="text-lg font-semibold">{zay.sumrem ? `${zay.sumrem} руб.` : '-'}</div>
                  </div>
                </div>
                <div className="mt-1">
                  <div className="text-sm text-gray-600">Заявленная неисправность:</div>
                  <textarea className="mt-1 p-2 border rounded-lg bg-white text-gray-800 w-full"
                    value={zay.neispravnost || ''}
                    readOnly />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default observer(ZayavkiSSite);
