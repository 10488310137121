import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../index';

const SubMenu = ({ toggleSidebar, onMenuItemClick }) => {
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const role = store.user.role;

    const menuItems = {
        dev: [
            { label: 'На главную', path: '' },
            { label: 'Выезд курьера/инженера', path: 'kurierpage' },
            { label: 'Ремонты техники', path: 'zayavkiremont' },
            { label: 'Вопросы с сайта', path: 'zayavkissayta' },
            { label: 'Пользователи', path: 'users' },
            { label: 'Организации', path: 'organisations' },
            { label: 'Цены на работы по картриджам', path: 'cart' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
        manager: [
            { label: 'На главную', path: '' },
            { label: 'Выезд курьера/инженера', path: 'kurierpage' },
            { label: 'Ремонты техники', path: 'zayavkiremont' },
            { label: 'Вопросы с сайта', path: 'zayavkissayta' },
            { label: 'Пользователи', path: 'users' },
            { label: 'Организации', path: 'organisations' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
        orgSotr: [
            { label: 'На главную', path: '' },
            { label: 'Выезд курьера/инженера', path: 'kurierpage' },
            { label: 'Ремонты техники', path: 'zayavkiremont' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
        orgAdmin: [
            { label: 'На главную', path: '' },
            { label: 'Выезд курьера/инженера', path: 'kurierpage' },
            { label: 'Ремонты техники', path: 'zayavkiremont' },
            { label: 'Организация', path: `organisations/cardorg/${store.user.orgID}` },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
        fis: [
            { label: 'На главную', path: '' },
            { label: 'Ремонты техники', path: 'zayavkiremont' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
        cur: [
            { label: 'На главную', path: '' },
            { label: 'Выезд курьера/инженера', path: 'kurierpage' },
            { label: 'Заявки на доработки', path: 'tickets' },
        ],
    };

    const menuItemList = menuItems[role];

    if (!menuItemList) {
        return (
            <div>
                Нет доступных пунктов меню для роли {role}
            </div>
        );
    }

    return (
        <div className="h-full px-2 py-2 text-gray-100">
            <ul className="space-y-2">
                {menuItemList.map((item, index) => (
                    <li key={index}>
                        <a onClick={() => {
                                navigate(item.path);
                                onMenuItemClick(); // Закрыть боковую панель
                            }}
                            className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-700 ease-in-out duration-200 transition-all transform hover:shadow-lg hover:scale-105"
                        >
                            <span className="ml-3 text-sm font-medium cursor-pointer">{item.label}</span>
                        </a>
                    </li>
                ))} 
            </ul>
        </div>
    );
};

export default observer(SubMenu);
